import firebase from 'firebase';
import { firebaseAuth } from './constants';

export function login(email, pw) {
	return firebaseAuth().signInWithEmailAndPassword(email, pw);
}

export function signup(email, pw) {
	return firebaseAuth().createUserWithEmailAndPassword(email, pw);
}

export function getUserProfile() {
	return firebase.auth().currentUser;
}

export function logout() {
	return firebaseAuth().signOut();
}

export function changePassword(email, currentPassword, newPassword) {
	var user = firebase.auth().currentUser;
	var credential = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
	return user
		.reauthenticateWithCredential(credential)
		.then(function () {
			user.updatePassword(newPassword);
		})
		.catch(function (error) {
			// An error happened.
		});
}

export function resetPassword(email) {
	return firebaseAuth().sendPasswordResetEmail(email);
}
